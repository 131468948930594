<template>
  <div class="view-req-photos photos">
<!--    <div v-if="data && data.images && data.images.length" class="photos__wrapper">-->
<!--      <a v-for="(photo, key) in data.images" :key="key" :href="`https://xn&#45;&#45;b1add.xn&#45;&#45;j1agca3a5c.xn&#45;&#45;p1ai/public/upload/avatar/${photo}`" target="_blank">-->
<!--        <img :src="getUserAvatar(photo, true)" alt="">-->
<!--      </a>-->
<!--    </div>-->
    <slider v-if="data && data.images && data.images.length" :data="data" />
    <div v-else class="empty">
      {{ $t('empty') }}
    </div>
  </div>
</template>

<script>
import { getUserAvatar } from "@/utils/user";

export default {
  name: "ViewRequestsBodyPhotos",
  components: {
    Slider: () => import('@/components/common/Slider'),
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      getUserAvatar
    }
  }
}
</script>

<style lang="scss" scoped>
.photos {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    box-shadow: none;
    background: none;
    border: none;
  }

  h1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    color: #343432;

    @media (max-width: 768px) {
      display: none;
    }
  }

  img {
    width: 100%;
    height: 100px;
    border-radius: 8.17241px;
    object-fit: cover;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 20px;
    a {
      display: flex;
    }

    @media (max-width: 1439px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 768px) {
      row-gap: 10px;
    }
  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
